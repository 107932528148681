import executeAPI from '~/helpers/executeAPI'

const ENDPOINT_TO_CHECK = '/api/healthz'
const ENDPOINT_VALUE_NAME = 'startupTime'
const CHECK_INTERVAL_MS = 30 * 1000

let previousValue: string | undefined
async function checkAppVersion() {
  try {
    const state = await executeAPI(ENDPOINT_TO_CHECK)
    const currentValue = state[ENDPOINT_VALUE_NAME]
    if (!currentValue) {
      throw new Error(`Healthz endpoint returns no ${ENDPOINT_VALUE_NAME}`)
    }
    if (previousValue === undefined) {
      previousValue = currentValue
      return
    }
    if (previousValue !== currentValue) {
      location.replace('/')
    }
  }
  catch (error) {
    console.error(`Checking build timestamp failed with "${error}"`)
  }
}

if (!import.meta.server) {
  setInterval(checkAppVersion, CHECK_INTERVAL_MS)
}
