import revive_payload_client_tD88Lk4Nw4 from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v55cdi6bygf3kcqp4wemgp2rw4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pwkZ54JRNH from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v55cdi6bygf3kcqp4wemgp2rw4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_aIy37Lzdqo from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v55cdi6bygf3kcqp4wemgp2rw4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T26CqnB2LT from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v55cdi6bygf3kcqp4wemgp2rw4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_UzAMKB1Hyz from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v55cdi6bygf3kcqp4wemgp2rw4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_OeuPh3gsXk from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v55cdi6bygf3kcqp4wemgp2rw4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_QyZvXTPFN6 from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v55cdi6bygf3kcqp4wemgp2rw4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_7OHWFjrsr1 from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.47_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._v55cdi6bygf3kcqp4wemgp2rw4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import ant_design_vue_client_McW8u2pXIG from "/app/plugins/ant-design-vue.client.ts";
import logger_client_NoN5FB4OWs from "/app/plugins/logger.client.ts";
export default [
  revive_payload_client_tD88Lk4Nw4,
  unhead_pwkZ54JRNH,
  router_aIy37Lzdqo,
  payload_client_T26CqnB2LT,
  navigation_repaint_client_UzAMKB1Hyz,
  check_outdated_build_client_OeuPh3gsXk,
  chunk_reload_client_QyZvXTPFN6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7OHWFjrsr1,
  ant_design_vue_client_McW8u2pXIG,
  logger_client_NoN5FB4OWs
]