interface Options {
  method?: string
  data?: any
  cardId?: string
}

const executeAPI = async function (path: string, { data, method = 'GET', cardId }: Options = {} as Options) {
  const { session } = useAuthStore()

  const headers = new Headers({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  })
  if (session.value.id) {
    headers.append('Authorization', `Bearer ${session.value.id}`)
  }
  if (cardId) {
    headers.append('Card-Authorization', `ID ${cardId}`)
  }

  const response = await fetch(path, {
    method,
    body: data === undefined ? undefined : JSON.stringify(data),
    headers,
  })

  let json
  try {
    json = await response.json()
  }
  catch {
    throw new Error('Anfragefehler: Fehler beim Parsen der Antwortdaten')
  }
  if (!response.ok) {
    throw new Error(`Anfragefehler: ${json?.message}`)
  }
  return json
}

export default executeAPI
