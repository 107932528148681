import { useStorage } from '@vueuse/core'
import { message } from 'ant-design-vue'
import type { UserSession } from '~/server/database/entities/UserSession'
import executeAPI from '~/helpers/executeAPI'

const ONE_MINUTE = 1000 * 10

const useAuthStore = function () {
  const persistentSession = useStorage('session:storage', () => ({} as UserSession))
  const session = computed(() => persistentSession.value)
  const isLoading = useState('isSessionLoading', () => false)
  const checkInterval = useState<number | null>('sessionCheckInterval', () => null)

  const clearSession = function (errorMessage?: string) {
    persistentSession.value = {} as UserSession
    if (checkInterval.value) {
      clearInterval(checkInterval.value)
      checkInterval.value = null
      if (errorMessage) {
        message.error(errorMessage)
        logToServer({ type: 'error', msg: 'Session cleared due to error', data: { error: errorMessage } })
      }
    }
  }

  const checkSession = async function () {
    if (!session.value.id) {
      return
    }
    try {
      await executeAPI('/api/auth/status')
    }
    catch (error: any) {
      clearSession(`Mit Fehler abgemeldet: ${error.message}`)
      console.error(`status returned error: ${error.message}`)
      navigateTo('/')
      throw error
    }
  }

  const startSessionCheckInterval = () => {
    if (!checkInterval.value) {
      checkInterval.value = window.setInterval(checkSession, ONE_MINUTE)
    }
  }

  const login = async (username: string, password: string, locationId: string) => {
    isLoading.value = true
    try {
      const sessionData: UserSession = await executeAPI('/api/auth/login', {
        method: 'POST',
        data: {
          username: username?.toLowerCase(),
          password,
          locationId,
        },
      })
      persistentSession.value = sessionData
      startSessionCheckInterval()
    }
    catch (error: any) {
      console.error(`Auth failed with error: ${error?.message}`)
      logToServer({ type: 'error', msg: 'Invalid Login request', data: { error } })
      throw error
    }
    finally {
      isLoading.value = false
    }
  }

  const logout = async function () {
    isLoading.value = true
    try {
      await executeAPI('/api/auth/logout')
    }
    catch (error: any) {
      console.error(`Logout failed with error: ${error.message}`)
      logToServer({ type: 'error', msg: 'Logout failed', data: { error } })
      throw error
    }
    finally {
      clearSession()
      isLoading.value = false
      logToServer({ type: 'info', msg: 'Session cleared due to logout' })
    }
  }
  if (session.value.id) {
    startSessionCheckInterval()
  }

  return { checkSession, session, isLoading, login, logout }
}

export default useAuthStore
