import { default as flowzupgRVOmRNMeta } from "/app/pages/flow.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: "flow",
    path: "/flow",
    meta: flowzupgRVOmRNMeta || {},
    component: () => import("/app/pages/flow.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  }
]